<template>
  <div class="sidebar sticky-bar p-4 rounded shadow">
    <div class="widget">
      <div class="row">
        <div class="col-6 mt-3 pt-2" v-for="(option, index) in menu" v-bind:key="index">
          <router-link v-bind:to="option.path"
            class="accounts rounded d-block shadow text-center py-3"
            v-bind:class="{ active: option.path == currentRoute,   }">
            <span class="pro-icons h3 text-muted">
              <i v-bind:class="`uil uil-${option.icon}`">
            </i></span>
            <h6 class="title text-dark h6 my-0">{{option.label}}</h6>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        menu: [
          {
            label: "Profile",
            icon: "dashboard",
            path: "/my-account",
          }, {
            label: "Quotes",
            icon: "receipt",
            path: "/my-account/quotes",
          }, {
            label: "Security",
            icon: "padlock",
            path: "/my-account/security",
          }, {
            label: "Beneficiaries",
            icon: "users-alt",
            path: "/my-account/beneficiaries",
          }, {
            label: "Messages",
            icon: "envelope-star",
            path: "/my-account/messages",
          }, {
            label: "Payment Methods",
            icon: "card-atm",
            path: "/my-account/payment-methods",
          },
        ],
      };
    },
    computed: {
      currentRoute() {
        return this.$route.path;
      },
    },
  };
</script>