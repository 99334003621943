<template>
  <section class="bg-profile d-table w-100 bg-primary" style="background: url('/images/account/bg.png') center center;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-3 text-md-left text-center">
                  <img v-bind:src="profilePhotoURL"
                    v-if="user"
                    class="avatar avatar-large rounded-circle shadow d-block mx-auto"
                    alt="Profile photo">
                </div>
                <div class="col-lg-10 col-md-9">
                  <div class="row align-items-end">
                    <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                      <h3 class="title mb-0">{{fullName}}</h3>
                      <small class="text-muted h6 mr-2">{{user && user.role}}</small>
                      <ul class="list-inline mb-0 mt-3">
                        <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted"
                            title="Instagram">
                            <instagram-icon class="fea icon-sm mr-2"></instagram-icon>krista_joseph
                          </a></li>
                        <li class="list-inline-item">
                          <router-link to="/account-setting" class="rounded text-muted" v-b-tooltip.hover
                            title="Settings">
                            <linkedin-icon class="fea icon-sm fea-social mr-2"></linkedin-icon>
                            Krista Joseph
                          </router-link>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-5 text-md-right text-center">
                      <ul class="list-unstyled social-icon social mb-0 mt-4">
                        <li class="list-inline-item ml-1">
                          <router-link to="/account-setting" class="rounded" v-b-tooltip.hover title="Settings">
                            <tool-icon class="fea icon-sm fea-social"></tool-icon>
                          </router-link>
                        </li>
                        <li class="list-inline-item ml-1">
                          <a href="javascript:;"
                            class="rounded"
                            v-b-tooltip.hover title="Sign Out"
                            v-on:click.prevent="onSignOut">
                            <log-out-icon class="fea icon-sm fea-social"></log-out-icon>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import {
    InstagramIcon,
    LinkedinIcon,
    ToolIcon,
    LogOutIcon,
  } from "vue-feather-icons";
  export default {
    components: {
      InstagramIcon,
      LinkedinIcon,
      ToolIcon,
      LogOutIcon,
    },
    computed: {
      user() {
        return this.$store.state.auth.user;
      },
      fullName() {
        return this.user ? `${this.user.name} ${this.user.lastName}` : "";
      },
      profilePhotoURL() {
        return this.user.profilePhotoURL || "/images/customer/customer.svg";
      }
    },
    methods: {
      onSignOut() {
        this.$store.dispatch("auth/SIGN_OUT")
          .then(() => this.$router.replace("/"))
          .catch(e => this.$swal(
            "Error",
            "An error has occurred when trying to sign out",
            "error"
          ));
      },
    },
  };
</script>